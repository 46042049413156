import http from '../http';

type CardType = 'PLATINUM' | 'TOP' | 'BLACK' | 'YELLOW' | 'UNLIMITED';
type PaymentMethod =
  | 'DINHEIRO'
  | 'CREDITO'
  | 'DEBITO'
  | 'VOUCHER'
  | 'PICPAY'
  | 'PIX'
  | 'DISCOUNT'
  | 'WALLET'
  | 'OTHER'
  | 'CRIPTO';
type SaleMode = 'TOTEM' | 'APP' | 'IFOOD' | 'WHATSAPP' | 'TOTEM_OFFLINE';
type SaleStatus = 'PENDING' | 'MANUAL' | 'RECEIVE' | 'PROGRESS' | 'FINISHED' | 'TOTEM';
type SaleToGo = 'LEVAR' | 'LOCAL' | 'CARRO' | 'DELIVERY';
type CategoryCustomDrink =
  | 'MILK'
  | 'DOSE'
  | 'SYRUP'
  | 'SAUCE'
  | 'CHANTILLY'
  | 'GRANULE'
  | 'OVOMALTINE'
  | 'PASTE';

interface optionsCustomDrink {
  category: CategoryCustomDrink;
  id: string;
  name: { br: string; en: string; es: string };
  quantity: number;
  pumps: number;
  measure: number;
  extraPumps: number;
}

interface Category {
  id: number;
  icon: string;
  name: {
    br: string;
    en: string;
    es: string;
  };
  queue: number;
  visible: boolean;
}

interface Coupon {
  card: false;
  cardType?: CardType;
  code: string;
  description: string;
  discount: number;
  enable: boolean;
  expireDate?: number[];
  id: number;
  lastUse: number;
  maxProducts: number;
  maxUses: number;
  percentage: boolean;
  uses: number;
  visible: boolean;
}

interface Additional {
  freeReward: boolean;
  id: number;
  image: string;
  name: {
    br: string;
    en: string;
    es: string;
  };
  price: number;
}

interface Product {
  category: Category;
  description: string;
  id: number;
  image: string;
  nameKDS: string;
  price: number;
}

interface ProductOptionRegistration {
  id: number;
  name: {
    br: string;
    en: string;
    es: string;
  };
  optionProduct: Array<{
    id: number;
    icon: string;
    name: {
      br: string;
      en: string;
      es: string;
    };
  }>;
}

interface OptionModel {
  id: number;
  name: {
    br: string;
    en: string;
    es: string;
  };
}
interface OptionGroupModel {
  id: number;
  name: {
    br: string;
    en: string;
    es: string;
  };
  options: OptionModel[];
}

interface SoldOptionsModel {
  id: number;
  optionGroups: OptionGroupModel;
  option: OptionModel;
}

interface CustomDrinkObject {
  name: string;
  type: string;
  price: number;
}

interface CustomDrink {
  name: string;
  configs: CustomDrinkObject[];
  sauces: CustomDrinkObject[];
  syrups: CustomDrinkObject[];
  pastes: CustomDrinkObject[];
}

interface SoldProducts {
  additional: Additional[];
  soldOptions: SoldOptionsModel[];
  productOptionRegistration: ProductOptionRegistration[];
  cups: number;
  id: number;
  names: string;
  observations: string;
  price: number;
  product: Product;
  quantity: number;
  upSelling: boolean;
  iceCube: boolean;
  chantilly: boolean;
  optionsCustomDrink: optionsCustomDrink[];
  coldCoffee: boolean;
  customDrink: CustomDrink;
}

interface TotemProps {
  name: string;
}

// removido variaveis nao usadas do model do backend, caso precise de alguma variavel que foi comentada pedir para algm de back
interface SaleData {
  // address: string;
  // cashbackCreate: number;
  // cashbackUsed?: string;
  changeFor?: number;
  deliveryFee?: number;
  coupon?: Coupon;
  // cpf: string;
  // date: Date;
  // deliveryFee?: number;
  // deviceId?: number;
  discount: number;
  // endDate?: Date;
  // faithfulness?: string;
  id: number;
  // invoice?: string;
  name: string;
  // notificationId?: number;
  observations: string;
  // orderId?: number;
  // paymentId?: number;
  paymentMethod: PaymentMethod;
  // printNf: boolean;
  // reference: string;
  saleMode: SaleMode;
  soldProductList: SoldProducts[];
  // startDate?: Date;
  status: SaleStatus;
  toGo: SaleToGo;
  totem?: TotemProps;
  club: boolean;
  // token?: string;
  // totalValue: number;
  // useCashback: boolean;
  // useFree: boolean;
  // useKds: boolean;
  value: number;
  currentDate: number;
  visibleDate: number;
}
export interface HistoryData extends SaleData {
  date: string;
}

export interface HistoryModel {
  content: HistoryData[];
  totalElements: number;
  totalPages: number;
}
interface CancelIfoodData {
  code: number;
  reason: string;
}

const sale = {
  list: async () => {
    const { status, data: result } = await http.get('/v3/kds/sale/pending/list');

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  receive: async (id: number) => {
    const { status, data } = await http.patch(`/v3/kds/sale/receive/${id}`);

    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
  start: async (id: number) => {
    const { status, data } = await http.patch(`/v3/kds/sale/start/${id}`);

    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
  finish: async (id: number) => {
    const { status, data } = await http.patch(`/v3/kds/sale/finish/${id}`);

    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
  cancel: async (id: number) => {
    const { status, data } = await http.patch(`/v3/kds/sale/cancel/${id}`);

    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
  cancelIfood: async ({ id, items }: { id: number; items: CancelIfoodData }) => {
    const { status, data } = await http.patch(`/v3/kds/sale/cancel/${id}`, items);

    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
  print: async (id: number) => {
    const { status, data } = await http.post(`/v3/kds/sale/print/${id}`);

    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
  roulete: async ({ queryKey }: { queryKey: any }) => {
    const [, { id }] = queryKey;
    const { status, data: result } = await http.get(`/v3/kds/sale/roulette/${id}`);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  approve: async (saleId: number) => {
    const { status, data } = await http.patch('/v3/kds/sale/payment/' + saleId);

    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
  history: async ({ queryKey }: { queryKey: any }) => {
    const [, { page }] = queryKey;
    const { status, data: result } = await http.get<HistoryModel>(`/v3/kds/sale/history/${page}`);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  order: async ({ queryKey }: { queryKey: any }) => {
    const [, { id }] = queryKey;
    const { status, data: result } = await http.get(`/v3/kds/sale/${id}`);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  offlineList: async () => {
    const { status, data: result } = await http.get<HistoryModel>(`/v3/kds/sale/pending/offline`);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
};

export default sale;

export type {
  SaleData,
  SoldProducts,
  SaleMode,
  SaleToGo,
  SaleStatus,
  PaymentMethod,
  CardType,
  CancelIfoodData,
  CategoryCustomDrink,
  Additional,
  CustomDrinkObject,
};
